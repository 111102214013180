import VButton from "components/v-core/v-button";
import VInput from "components/v-core/v-input";
import VTabs from "components/v-core/v-tabs";
import { MasterContext } from "context/MasterContext";
import useProjects from "hooks/useProjects";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { formatNumber } from "utils/common";
import { renderStock } from "utils/render";

const defaultState = {
  fromAmount: 0,
  toAmount: 0,
  percent: 0,
  price: 0,
};

type IProps = {
  type: string;
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
};

const Order = ({ type, selectedTab, setSelectedTab }: IProps) => {
  const [stockState, setStockState] = useState(defaultState);
  const { balances, stocks, lockedBalances, stockDefault } =
    useContext(MasterContext);
  const [exchange, setExchangeList] = useState([]);
  const [isShowAll, setIsShowAll] = useState(true);

  useEffect(() => {
    if (stocks && stocks.length > 0 && balances) {
      const _exchange = stocks.map((token: any) => ({
        ...token,
        balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
        locked_balances: lockedBalances[token.symbol]
          ? Number(lockedBalances[token.symbol])
          : 0,
      }));

      const _exchangeVnex = _exchange.find(
        (w: any) => w.symbol === stockDefault
      );
      const _exchangeNormal = _exchange.filter(
        (w: any) =>
          w.symbol !== stockDefault && (isShowAll ? true : +w.balance > 0)
      );
      setExchangeList([_exchangeVnex, ..._exchangeNormal]);
    }
  }, [stocks, balances, lockedBalances, isShowAll]);

  const currentExchange = exchange.find(
    (item) => item?.symbol?.toLowerCase() === selectedTab
  );

  return (
    <div className="border-none lg:dark:border lg:dark:border-gray-300 bg-vdb-gray-800 dark:bg-vdb-default-white rounded flex flex-col p-2 gap-y-2 m-2">
      {currentExchange && (
        <div className="flex justify-between p-1">
          <div className="flex items-center gap-1">
            <span className="text-sm text-white">Available: </span>
            <span className="">
              {formatNumber(+currentExchange.balance)}
            </span>{" "}
            {renderStock(currentExchange?.symbol)}
          </div>
          <button>
            <img src="/add.svg" alt="" />
          </button>
        </div>
      )}

      <VInput
        type={"number"}
        placeholder="Price"
        suffixIcon={"~ USDT"}
        value={stockState.price}
        onChange={(e) =>
          setStockState({ ...stockState, price: +e.target.value })
        }
      />

      <VInput
        type={"number"}
        placeholder="Total"
        value={stockState.fromAmount}
        onChange={(e) =>
          setStockState({ ...stockState, fromAmount: +e.target.value })
        }
      />
      <div className="py-1 mb-4">
        {/* <Range
          className={rangeClassName}
          value={stockState?.percent || 0}
          onChange={(e) => handleChange("percent", +e.target.value)}
        /> */}
      </div>

      <VInput
        type={"number"}
        placeholder="0"
        value={stockState.toAmount}
        onChange={(e) =>
          setStockState({ ...stockState, toAmount: +e.target.value })
        }
      />

      <VButton
        className={`w-full h-[38px] rounded-sm ${
          type === "buy" ? "bg-blue-500" : "bg-red-500"
        } text-black-1`}
      >
        {type === "buy"
          ? `BUY ${selectedTab?.toUpperCase()}`
          : `SELL ${selectedTab?.toUpperCase()}`}
      </VButton>
    </div>
  );
};

export default Order;
