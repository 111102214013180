/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { isMobile } from "react-device-detect";

import { Form, FormItem, FormTitle } from "../styles";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import useSSO from "../../../hooks/useSSO";
import VButton from "components/v-core/v-button";
import SocialsLogin from "../socials-login";
import VInput from "components/v-core/v-input";
import { ThemeContext } from "context/ThemeContext";
import { MasterContext } from "context/MasterContext";
import qs from "query-string";

interface IProps {
  [key: string]: any;
}

function Login(props: IProps) {
  const { settings } = useContext(MasterContext);
  const { getThemeByKey, themesSetting } = useContext(ThemeContext);
  const location = useLocation();
  const query = qs.parse(location.search);
  const history = useHistory();
  const logos = settings?.logos;

  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [hoverField, setHoverField] = useState(null);

  const { checkLogin } = useSSO(props, setLoading);
  const inputTheme = getThemeByKey("auth.input");

  const { callback } = query;

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
  } = useForm();

  const confirmLogin = async (data) => {
    try {
      setErrMessage("");
      setLoading(true);
      const res: any = await AuthAPI.login(data);
      if (res) {
        if (res.require_2fa || res.require_email) {
          history.push(
            `${ROUTES.VERIFY_LOGIN}?twofa=${res.require_2fa}&&email=${
              res.require_email
            }&&token=${res.token}&&${callback ? `callback=${callback}` : ""}`
          );
        } else {
          localStorage.setItem("auth", res.token);
          history.push(
            `${ROUTES.DASHBOARD}${callback ? `?callback=${callback}` : ""}`
          );
        }
        setLoading(false);
      }
    } catch (error) {
      setErrMessage(error?.errors || "Invalid email or password!");
      setLoading(false);
      if (error?.errors === "Email inactive!") {
        history.push(
          `${ROUTES.VERIFY_EMAIL}?email=${data.email}&&${
            callback ? `callback=${callback}` : ""
          }`
        );
      }
    }
  };

  const handleClickImage = () => {
    window.open(logos?.rightItem?.link || "#");
  };

  useEffect(() => {
    checkLogin();
  }, [query]);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      history.push(
        `${ROUTES.DASHBOARD}${callback ? `?callback=${callback}` : ""}`
      );
    }
  }, []);

  return (
    <div className="container">
      <div className="flex max-md:flex-col justify-center items-center gap-10">
        <div className="flex flex-col items-center mt-4 z-50">
          <Form className="" onSubmit={handleSubmit(confirmLogin)}>
            {!isMobile && (
              <div className="flex flex-col items-center gap-y-4">
                {logos?.logoWelcome && (
                  <img
                    src={logos?.logoWelcome}
                    alt="welcome"
                    className="h-[50px] max-w-[250px]"
                  />
                )}
                <FormTitle>Account Sign in</FormTitle>
              </div>
            )}
            <FormItem>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <VInput
                    label="Email"
                    labelStyle={{ color: themesSetting.layout.textLoginPage }}
                    {...register("email")}
                    placeholder="Email"
                    hasError={Boolean(errors.email)}
                    errMessage={errMessage}
                    wrapperClassName={"bg-transparent gap-y-1 px-0"}
                    labelClassName={`text-xs font-bold `}
                    inputWrapperClassName={`w-full rounded border-[1px]`}
                    onMouseEnter={() => setHoverField("email")}
                    onMouseLeave={() => setHoverField(null)}
                    className={`w-full bg-transparent p-2 text-white`}
                    inputStyle={{
                      ...inputTheme,
                      borderColor:
                        hoverField === "email"
                          ? inputTheme?.hover?.borderColor
                          : inputTheme?.borderColor,
                    }}
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <Controller
                name="password"
                {...register("password")}
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <VInput
                    type="password"
                    label="Password"
                    labelStyle={{ color: themesSetting.layout.textLoginPage }}
                    placeholder="Password"
                    hasError={Boolean(errors.password)}
                    errMessage={errMessage}
                    wrapperClassName={"bg-transparent gap-y-1 px-0"}
                    labelClassName={`text-xs font-bold`}
                    inputWrapperClassName={`w-full border-[1px] rounded`}
                    className="w-full bg-transparent p-2 text-white"
                    onMouseEnter={() => setHoverField("password")}
                    onMouseLeave={() => setHoverField(null)}
                    inputStyle={{
                      ...inputTheme,
                      borderColor:
                        hoverField === "password"
                          ? inputTheme?.hover?.borderColor
                          : inputTheme?.borderColor,
                    }}
                    {...field}
                  />
                )}
              />
            </FormItem>
            <FormItem>
              <VButton
                loading={loading}
                className="hover:!opacity-80 cursor-pointer !rounded-lg !border-none py-2"
              >
                Sign in
              </VButton>
            </FormItem>

            <SocialsLogin />

            <div className="flex flex-col gap-y-2 pt-2">
              <div
                className="flex items-center font-medium text-sm cursor-pointer"
                onClick={() => history.push(ROUTES.FORGOT_PASSWORD)}
              >
                Forgot password?
              </div>
              <div className="flex items-center font-medium text-sm gap-x-1">
                <span>Don’t have account?</span>
                <Link to={ROUTES.REGISTER} className="no-underline">
                  <span className="text-blue">Sign up</span>
                </Link>
              </div>
            </div>
          </Form>
        </div>
        {!isMobile && (
          <div className="flex flex-col items-center gap-y-6 justify-center z-50">
            {logos?.rightItem && logos?.rightItem?.image ? (
              <img
                src={logos?.rightItem?.image}
                alt="image-right"
                className="object-contain h-[200px] md:h-[250px] cursor-pointer"
                onClick={handleClickImage}
              />
            ) : (
              <svg
                width="121"
                height="120"
                viewBox="0 0 121 120"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  // eslint-disable-next-line max-len
                  d="M38 7.5H8V37.5H38V7.5ZM45.5 0V45H0.5V0H45.5ZM15.5 15H30.5V30H15.5V15ZM113 7.5H83V37.5H113V7.5ZM120.5 0V45H75.5V0H120.5ZM90.5 15H105.5V30H90.5V15ZM38 82.5H8V112.5H38V82.5ZM45.5 75V120H0.5V75H45.5ZM15.5 90H30.5V105H15.5V90ZM53 0H60.5V7.5H53V0ZM60.5 7.5H68V15H60.5V7.5ZM53 15H60.5V22.5H53V15ZM60.5 22.5H68V30H60.5V22.5ZM53 30H60.5V37.5H53V30ZM60.5 37.5H68V45H60.5V37.5ZM53 45H60.5V52.5H53V45ZM53 60H60.5V67.5H53V60ZM60.5 67.5H68V75H60.5V67.5ZM53 75H60.5V82.5H53V75ZM60.5 82.5H68V90H60.5V82.5ZM53 90H60.5V97.5H53V90ZM60.5 97.5H68V105H60.5V97.5ZM53 105H60.5V112.5H53V105ZM60.5 112.5H68V120H60.5V112.5ZM113 60H120.5V67.5H113V60ZM8 60H15.5V67.5H8V60ZM15.5 52.5H23V60H15.5V52.5ZM0.5 52.5H8V60H0.5V52.5ZM30.5 52.5H38V60H30.5V52.5ZM38 60H45.5V67.5H38V60ZM45.5 52.5H53V60H45.5V52.5ZM68 60H75.5V67.5H68V60ZM75.5 52.5H83V60H75.5V52.5ZM83 60H90.5V67.5H83V60ZM90.5 52.5H98V60H90.5V52.5ZM98 60H105.5V67.5H98V60ZM105.5 52.5H113V60H105.5V52.5ZM113 75H120.5V82.5H113V75ZM68 75H75.5V82.5H68V75ZM75.5 67.5H83V75H75.5V67.5ZM83 75H90.5V82.5H83V75ZM98 75H105.5V82.5H98V75ZM105.5 67.5H113V75H105.5V67.5ZM113 90H120.5V97.5H113V90ZM68 90H75.5V97.5H68V90ZM75.5 82.5H83V90H75.5V82.5ZM90.5 82.5H98V90H90.5V82.5ZM98 90H105.5V97.5H98V90ZM105.5 82.5H113V90H105.5V82.5ZM113 105H120.5V112.5H113V105ZM75.5 97.5H83V105H75.5V97.5ZM83 105H90.5V112.5H83V105ZM90.5 97.5H98V105H90.5V97.5ZM98 105H105.5V112.5H98V105ZM75.5 112.5H83V120H75.5V112.5ZM90.5 112.5H98V120H90.5V112.5ZM105.5 112.5H113V120H105.5V112.5Z"
                  fill="currentColor"
                />
              </svg>
            )}
            <h5>{`${
              logos?.rightItem?.text
                ? logos?.rightItem?.text
                : "Sign in with QR Code"
            }`}</h5>
          </div>
        )}
      </div>
    </div>
  );
}

export default Login;
