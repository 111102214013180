"use client";
import VTabs from "components/v-core/v-tabs";

type TabSelectorProps = {
  tabs: any[];
  tabActive: string;
  onChangeTabs: (tabValue: string) => void;
  handleClickFunctionButton?: (type?: string) => void;
};

const Tokens = ({
  tabs,
  tabActive,
  onChangeTabs,
  handleClickFunctionButton,
}: TabSelectorProps) => {
  const handleTabClick = (tabValue: string) => {
    if (handleClickFunctionButton) handleClickFunctionButton(tabValue);
    onChangeTabs(tabValue);
  };
  return (
    <div className="min-h-full dark:bg-vdb-default-white bg-vdb-gray-800 lg:dark:bg-vdb-light-white lg:bg-vdb-dark-gray p-2 rounded-sm">
      <input
        type="text"
        id="search-navbar"
        className="block w-full bg-black-1 px-2 py-1.5 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 "
        placeholder="Search"
      />
      <div className="flex items-center mt-3">
        <div className="flex mr-5">
          <img src="/star.svg" alt="" />
          <span className="text-white">Token</span>
        </div>
        <VTabs
          tabs={tabs.map((tab, index) => ({
            name: tab?.symbol?.toUpperCase(),
            value: tab?.symbol,
          }))}
          tabActive={tabActive}
          onChangeTabs={handleTabClick}
        />
      </div>
      <div className="flex justify-between px-2 text-white">
        <p>Pair</p>
        <p>Price</p>
      </div>
      <div className="min-h-full h-[250px] overflow-y-scroll no-scrollbar">
        {/* <Pairs data={dataPair} /> */}
      </div>
    </div>
  );
};

export default Tokens;
