"use client";

import VTable from "components/v-core/v-table";
import VTabs from "components/v-core/v-tabs";
import { useState } from "react";

const MyHistory = ({ data = [] }) => {
  const [selectedTab, setSelectedTab] = useState("my_order_pending");

  const tabs = [
    {
      name: "My order pending",
      value: "my_order_pending",
    },
    {
      name: "My order success",
      value: "my_order_success",
    },
  ];

  const columnsOrder = [
    {
      field: "type",
      title: `Type`,
    },
    {
      field: "price",
      title: "Price",
    },
    {
      field: "from_amount",
      title: `From`,
    },
    {
      field: "to_amount",
      title: `To`,
    },
    {
      field: "date",
      title: `Created At`,
    },
    {
      field: "cancel",
      title: `Action`,
    },
  ];

  const columnsTransaction = [
    {
      field: "seller",
      title: `Seller`,
    },
    {
      field: "buyer",
      title: `Buyer`,
    },
    {
      field: "price",
      title: "Price",
    },
    {
      field: "from_amount",
      title: `From`,
    },
    {
      field: "to_amount",
      title: `To`,
    },
    {
      field: "status",
      title: `Status`,
    },
    {
      field: "date",
      title: `Updated At`,
    },
  ];

  const filteredData =
    selectedTab === "my_order_pending"
      ? data.filter((item) => item.status === "pending")
      : data.filter((item) => item.status === "success");

  const columns =
    selectedTab === "my_order_pending" ? columnsOrder : columnsTransaction;

  return (
    <div className="flex flex-col w-full">
      <ul className="flex -mb-px text-sm font-medium text-center text-white dark:text-gray-400">
        <VTabs
          tabs={tabs}
          tabActive={selectedTab}
          onChangeTabs={(i) => setSelectedTab(i)}
        />
      </ul>
      <div className="w-full h-full ">
        <VTable columns={columns} data={filteredData} />
      </div>
    </div>
  );
};

export default MyHistory;
