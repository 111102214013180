/* eslint-disable indent */
import { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
import Modal from "components/v-core/v-modal";
import VTable from "components/v-core/v-table";
import VPagination from "components/v-core/v-pagination";
import { Coin, Description, ModalHistoryWrapper, Available } from "../styles";
import { convertTimestamptoDate } from "utils/times";
import { ModalContent } from "pages/my/ibo-project/styles";
import Input from "components/v-core/v-input";
import CongratulationInvests from "components/congratulations";
import { MasterContext } from "context/MasterContext";
import Icons from "helper/icons";
import { renderAmount, renderStatus, renderStock } from "utils/render";
import {
  Menu,
  // Transition,
} from "@headlessui/react";
import { ThemeContext } from "context/ThemeContext";
import PurchaseService from "api/purchases";
import VButton from "components/v-core/v-button";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const columnsHistoriesProfit = [
  // { key: "unlock", title: "Unlock", dataIndex: "unlock" },
  {
    key: "Date",
    title: "Date",
    render: (item) => {
      return convertTimestamptoDate(item.date);
    },
  },
  {
    key: "amount",
    title: "Amount",
    render: (item) => {
      return `${item.amount} (${String(item.stock).toUpperCase()})`;
    },
  },
  {
    key: "Hash",
    title: "Hash",
    render: (item: any) => (
      <a
        href={`${
          process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
        }/tx/${item.hash}`}
        target={"_blank"}
        rel="noreferrer"
      >
        {item.hash?.slice(0, 20)}...
      </a>
    ),
  },
];

const History = () => {
  const { getCryptosByObject, stocksPrice } = useContext(MasterContext);
  const { themesSetting } = useContext(ThemeContext);

  const [filter, setFilter] = useState({ page: 1, limit: 6 });

  const [loading, setLoading] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [investSelected, setInvestSelected] = useState(null);
  const [histories, setHistories] = useState({
    docs: [],
    totalPages: 0,
    totalDocs: 0,
  });
  const stocks = getCryptosByObject();
  const [historiesProfit, setHistoriesProfit] = useState({
    docs: [],
  });

  const [state, setState] = useState({
    isOpen: false,
    amountFrom: null,
    amountTo: null,
  });
  const [openModalCongratulation, setOpenModalCongratulation] =
    useState<boolean>(false);

  const [isOpen, setIsOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [buttonRect, setButtonRect] = useState(null);

  const menuItemsRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      const menuItems = menuItemsRef.current;

      if (menuItems && buttonRect) {
        // Lấy vị trí của menu items và nút bấm
        const rect = menuItems.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Kiểm tra chiều cao thẻ cha hoặc cửa sổ
        if (windowHeight - buttonRect.bottom < rect.height) {
          // Không đủ khoảng trống để hiển thị xuống dưới => đặt menu lên trên
          setMenuPosition({
            top: buttonRect.top - rect.height,
            left: buttonRect.left,
          });
        } else {
          // Đủ khoảng trống để hiển thị xuống dưới
          setMenuPosition({
            top: buttonRect.bottom,
            left: buttonRect.left,
          });
        }
      }
    }
  }, [isOpen]);

  const handleMenuOpen = (event, open) => {
    setIsOpen(open);
    const _buttonRect = event.currentTarget.getBoundingClientRect();
    setButtonRect(_buttonRect);
  };

  const columns = [
    {
      key: "",
      name: "",
      render: (item) => renderAction(item),
    },

    {
      key: "id",
      name: "ID",
      customHeader: <span className="font-bold text-purple-500">ID</span>,
      dataIndex: "_id",
      render: (item: any) => {
        return item?._id.slice(0, 4) + "..." + item?._id.slice(-3);
      },
    },
    {
      key: "Package",
      name: "Package",
      render: (item) => {
        return `${item.package_id.name} (${item.package_id.type})`;
      },
    },
    {
      key: "receipt",
      name: "Receipt",
      render: (item) => (
        <VButton
          onClick={() =>
            window.open(
              `/invoice/investment/${item.package_id["name"]}/${item._id}`
            )
          }
          type="outline"
          className="flex items-center gap-x-2 !border-none px-4 py-2 text-sm cursor-pointer"
          iconLeft={<Icons iconName="receipt" />}
        />
      ),
    },
    {
      key: "claim_profit",
      name: "Claim Profit",
      render: (item) => (
        <VButton
          type="outline"
          className={`flex items-center gap-x-2 !border-none px-4 py-2 text-sm cursor-pointer ${
            !(
              item.investment.profit.status !== "finished" &&
              +item.investment.profit?.remaining > 0
            ) && "opacity-40"
          }`}
          disabled={
            !(
              item.investment.profit.status !== "finished" &&
              +item.investment.profit?.remaining > 0
            )
          }
          onClick={() => {
            setInvestSelected(item);
            setState({
              ...state,
              isOpen: true,
            });
          }}
          iconLeft={<Icons iconName="claim_profit" />}
        />
      ),
    },
    {
      key: "congratulation",
      name: "Congratulation",
      render: (item: any) => (
        <VButton
          onClick={() => {
            setInvestSelected(item);
            setOpenModalCongratulation(true);
          }}
          type="outline"
          className="flex items-center gap-x-2 !border-none px-4 py-2 text-sm cursor-pointer"
          iconLeft={<Icons iconName="congratulation" />}
        />
      ),
    },
    {
      key: "amount",
      name: "Amount",
      render: (item) => (
        <span>
          {renderAmount(item?.investment?.amount)} (
          {String(item.package_id.invest.stock).toUpperCase()})
        </span>
      ),
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (item: any) => renderStatus(item.status),
    },
    {
      key: "principal",
      name: "Principal",
      render: (item) => {
        return (
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-1">
              <span className="opacity-60">Total:</span>
              <span>
                {formatNumber(item.investment.principal?.total)} (
                {String(item.investment.principal?.stock).toUpperCase()})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60">Claimed:</span>
              <span className="text-green-500">
                {formatNumber(item.investment.principal?.claimed)} (
                {String(item.investment.principal?.stock).toUpperCase()})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60">Remaining:</span>
              <span className="text-red-500">
                {formatNumber(item.investment.principal?.remaining)} (
                {String(item.investment.principal?.stock).toUpperCase()})
              </span>
            </div>
          </div>
        );
      },
    },
    {
      key: "profit",
      name: "Profit",
      render: (item) => {
        return (
          <div className="flex flex-col gap-y-2">
            <div className="flex gap-x-1">
              <span className="opacity-60">Total:</span>
              <span>
                {formatNumber(item.investment.profit?.total)} (
                {renderStock(item.investment.profit?.stock)})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60"> Claimed:</span>
              <span className="text-green-500">
                {formatNumber(item.investment.profit?.claimed)} (
                {renderStock(item.investment.profit?.stock)})
              </span>
            </div>
            <div className="flex gap-x-1">
              <span className="opacity-60">Remaining:</span>
              <span className="text-red-500">
                {formatNumber(item.investment.profit?.remaining, 0)} (
                {renderStock(item.investment.profit?.stock)})
              </span>
            </div>
          </div>
        );
      },
    },
  ];

  const renderAction = (item) => {
    const isClaimPrincipal =
      item.investment.principal.status !== "finished" &&
      +item.investment.principal?.remaining > 0;
    const isClaimProfit =
      item.investment.profit.status !== "finished" &&
      +item.investment.profit?.remaining > 0;

    return (
      <Menu as="div" className="relative inline-block text-left">
        {({ open }) => (
          <>
            <div>
              <Menu.Button
                ref={menuButtonRef}
                className="flex items-center rounded-full "
                onClick={(e) => handleMenuOpen(e, open)}
                onBlur={() => setIsOpen(open)}
              >
                <span className="sr-only">Open options</span>
                <Icons iconName="more" />
              </Menu.Button>
            </div>
            {/* <Transition
              as={Fragment}
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            > */}
            {open && (
              <Menu.Items>
                <div
                  ref={menuItemsRef}
                  className="fixed py-1 z-[99999] rounded-md shadow-md focus:outline-none"
                  style={{
                    top: `${menuPosition.top}px`,
                    left: `${menuPosition.left}px`,
                    ...themesSetting.card,
                  }}
                >
                  <Menu.Item>
                    <div
                      onClick={(_) =>
                        window.open(
                          `/invoice/investment/${item.package_id["name"]}/${item._id}`
                        )
                      }
                      className="flex items-center gap-x-2 px-4 py-2 text-sm cursor-pointer"
                    >
                      <Icons iconName="receipt" />
                      <span>Receipt</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div
                      onClick={(_) => {
                        setInvestSelected(item);
                        setOpenModalCongratulation(true);
                      }}
                      className="flex items-center gap-x-2 px-4 py-2 text-sm cursor-pointer"
                    >
                      <Icons iconName="congratulation" />
                      <span>Congratulation</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item>
                    <div
                      onClick={() => {
                        openModalHistory(item);
                      }}
                      className={classNames(
                        "flex items-center gap-x-2 px-4 py-2 text-sm cursor-pointer"
                      )}
                    >
                      <Icons iconName="histories" />
                      <span>Histories</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item disabled={!isClaimProfit}>
                    <div
                      className={`flex items-center gap-x-2 px-4 py-2 text-sm cursor-pointer ${
                        !isClaimProfit && "opacity-40"
                      }`}
                      onClick={() => {
                        setInvestSelected(item);
                        setState({
                          ...state,
                          isOpen: true,
                        });
                      }}
                    >
                      <Icons iconName="claim_profit" />
                      <span>Claim Profit</span>
                    </div>
                  </Menu.Item>
                  <Menu.Item disabled={!isClaimPrincipal}>
                    <div
                      onClick={() => handleClaim(item._id, "principal")}
                      className={`flex items-center gap-x-2 px-4 py-2 text-sm cursor-pointer ${
                        !isClaimPrincipal && "opacity-40"
                      }`}
                    >
                      <Icons iconName="claim_profit" />
                      <span>Claim Principal</span>
                    </div>
                  </Menu.Item>
                </div>
              </Menu.Items>
            )}
            {/* </Transition> */}
          </>
        )}
      </Menu>
    );
  };

  useEffect(() => {
    getPurchases();
  }, [filter]);

  const onFilter = (type: string) => {
    if (type === "next") {
      setFilter((prev) => ({
        ...prev,
        page: prev.page + 1,
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        page: prev.page - 1,
      }));
    }
  };

  const getPurchases = async () => {
    try {
      const res: any = await PurchaseService.getPurchase({
        params: {
          type: "package",
        },
        query: filter,
      });
      if (res && res?.docs) {
        setHistories(res);
      }
    } catch (error) {}
  };

  const handleClosed = () => {
    setOpenModal(false);
    setOpenModalCongratulation(false);
    setInvestSelected(null);
  };

  const handleChange = (type, value, price) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      amountTo = +value / +price;
    } else {
      amountTo = +value;
      amountFrom = +value * +price;
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleClosedClaim = () => {
    setInvestSelected(null);
    setState({
      ...state,
      isOpen: false,
      amountFrom: 0,
      amountTo: 0,
    });
  };

  const handleClaim = async (purchase_id, type, amount = 0) => {
    try {
      setLoading(true);
      await PurchaseService.claim({
        body: {
          type,
          purchase_id,
          amount,
        },
      });
      getPurchases();
      handleClosedClaim();
      setLoading(false);
      toast.success("Claim success!");
    } catch (error) {
      setLoading(null);
      toast.error(error.errors);
    }
  };

  const openModalHistory = (item) => {
    getHistoriesProfit(item);
    setInvestSelected(item);
    setOpenModal(true);
  };

  const getHistoriesProfit = async (item) => {
    try {
      const res: any = await PurchaseService.getHistoriesProfit({
        params: {
          purchaseId: item._id,
        },
        query: {
          limit: 50,
          page: 1,
        },
      });
      if (res) setHistoriesProfit(res);
    } catch (error) {}
  };

  const renderModal = (investSelected: any) => {
    if (investSelected) {
      return (
        <ModalHistoryWrapper>
          {" "}
          <VTable
            columns={columnsHistoriesProfit}
            data={historiesProfit.docs || []}
          ></VTable>
        </ModalHistoryWrapper>
      );
    }
  };

  const renderModalClaim = (investSelected) => {
    if (investSelected) {
      const from = investSelected?.investment?.profit.stock;
      const to = investSelected?.investment?.profit.stock_claim;
      const price = stocksPrice[to]["vnd"] / stocksPrice[from]["vnd"];
      return (
        <div>
          <div className="flex justify-center items-center">
            <img
              className="w-6 h-6"
              src={investSelected.package_id.icon}
              alt=""
            />
            {/* <span>VZONEX</span> */}
            <span>{investSelected.package_id.name}</span>
          </div>
          <ModalContent>
            <Coin>
              <div className="flex items-center">
                <img src={stocks[from] ? stocks[from]["logo"] : ""} alt="" />
                <span>{from.toUpperCase()}</span>
              </div>
              <Input
                name="amountFrom"
                // type="number"
                isCurrency={true}
                value={state.amountFrom}
                placeholder="0.00"
                onValueChange={(e) => handleChange("from", e, price)}
              />
              <Available>
                <span>Available: </span>
                <span>
                  {formatNumber(
                    investSelected?.investment?.profit?.remaining || 0
                  )}
                </span>
              </Available>
            </Coin>
            <div className="flex my-2 justify-center">
              <img src="/assets/images/icons/transfer.svg" alt="" />
            </div>
            <Coin>
              <div className="flex items-center">
                <img src={stocks[to] ? stocks[to]["logo"] : ""} alt="" />
                <span>{to.toUpperCase()}</span>
              </div>

              <Input
                isCurrency={true}
                name="amountTo"
                value={state.amountTo}
                placeholder="0.00"
                onValueChange={(e) => handleChange("to", e, price)}
              />
            </Coin>
            <Description>
              <span>Rate</span>
              <span>
                1 {String(to).toUpperCase()} = {price.toFixed(2)}{" "}
                {String(from).toUpperCase()}
              </span>
            </Description>
            {/* <Description>
                <span>Fee</span>
                <span>0.5%</span>
              </Description> */}
            {/* <Button
              onClick={() =>
                handleClaim(investSelected._id, "profit", state.amountFrom)
              }
              loading={loading}
            >
              Claim
            </Button> */}
          </ModalContent>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-col">
      <VTable columns={columns} data={histories.docs} />
      <VPagination
        page={filter?.page}
        totalPages={histories?.totalPages}
        limit={filter?.limit}
        totalDocs={histories?.totalDocs}
        onFilter={onFilter}
      />

      <Modal
        isOpen={state.isOpen}
        loading={loading}
        children={renderModalClaim(investSelected)}
        onCancel={handleClosedClaim}
        onConfirm={() =>
          handleClaim(investSelected._id, "profit", state.amountFrom)
        }
        centered={true}
      />
      <Modal
        isOpen={openModal}
        children={renderModal(investSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <CongratulationInvests
        type="investsSuccess"
        background={investSelected?.package_id?.backgroundSuccess}
        userName={investSelected?.user_id?.fullName}
        avataUser={investSelected?.user_id?.avatar}
        logoProject={investSelected?.package_id?.icon}
        logoLevel={investSelected?.package_id?.logoLevel}
        amount={investSelected?.investment?.amount}
        symbol={investSelected?.package_id?.invest?.stock}
        createdAt={investSelected?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
        products={investSelected?.package_id?.reward?.products}
      />
    </div>
  );
};

export default History;
