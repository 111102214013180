import { useRef } from "react";
import Container, {
  Logo,
  AvataUser,
  LogoLevel,
  QRInvests,
  NameUser,
  AmountInvests,
  // DateActive,
} from "./styles";

import QRCode from "react-qr-code";

import { formatCurrency } from "utils/common";
import { convertS3Link } from "api/s3-request";

import { isMobile } from "react-device-detect";
import Modal from "components/v-core/v-modal";
import PreviewAndDownload from "components/v-download";
import { exportComponentAsJPEG } from "react-component-export-image";

const View = (props: any) => {
  const imgRef = useRef();
  const {
    type,
    background,
    userName,
    logoProject,
    logoLevel,
    amount,
    symbol,
    // createdAt,
    openModalCongratulation,
    setOpenModalCongratulation,
    levelName,
    products,
  } = props;

  const downloadImage = async () => {
    exportComponentAsJPEG(imgRef, {
      fileName: `Invests_vzonex_${new Date().getTime()}` || "vzonex",
    });
  };

  const renderModalCongratulation = () => {
    if (props) {
      return (
        <PreviewAndDownload
          srcImg={background}
          download={downloadImage}
          closeModal={setOpenModalCongratulation}
        >
          <>
            <Container ref={imgRef}>
              <img src={convertS3Link(background)} alt="bg" />
              <Logo type={type}>
                <img src={convertS3Link(logoProject)} alt="logoProject" />
              </Logo>
              <AvataUser className="!size-[35%]" type={type}>
                {/* <img src={convertS3Link(avataUser)} alt="avataUser" /> */}
                <img
                  src={`/assets/images/social/canvanex.png`}
                  alt="avataUser"
                  className="!w-full !h-full object-cover"
                />
              </AvataUser>
              <LogoLevel className="!top-[43%] !left-[59%]" type={type}>
                <img src={convertS3Link(logoLevel)} alt="logoLevel" />
              </LogoLevel>
              <NameUser className="!bottom-[40%]" type={type}>
                {userName}
              </NameUser>
              {type !== "level-up" ? (
                <>
                  <AmountInvests type={type}>
                    <div className="_container_amount">
                      {formatCurrency(amount)} {symbol?.toUpperCase()}
                    </div>
                  </AmountInvests>
                  {products?.length > 0 && (
                    <div className="absolute top-[78%] w-full flex flex-col items-center gap-y-1">
                      {products?.map((product: any) => (
                        <div
                          key={product.index}
                          className="flex items-center gap-x-4"
                        >
                          <span className="text-xl font-bold">
                            {product.name} : {product.amount}
                          </span>
                          <img
                            src={convertS3Link(product.image)}
                            alt=""
                            className="w-6 object-cover"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <AmountInvests type={type}>
                  <div className="_container_amount">{levelName}</div>
                </AmountInvests>
              )}
              <QRInvests type={type}>
                <QRCode value={window.origin} size={isMobile ? 46 : 94} />
              </QRInvests>
              {/* <DateActive>
                {new Date(createdAt).toLocaleString("en-GB")}
              </DateActive> */}
            </Container>
          </>
        </PreviewAndDownload>
      );
    }
  };

  return (
    <>
      <Modal
        isOpen={openModalCongratulation}
        fullscreen={true}
        showImage={true}
        children={renderModalCongratulation()}
        onCancel={() => setOpenModalCongratulation(false)}
      />
    </>
  );
};

export default View;
