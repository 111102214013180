/* eslint-disable no-useless-escape */
import Button from "components/v-core/v-button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Scanner from "react-webcam-qr-scanner";
import { useHistory } from "react-router-dom";

import { copyText } from "utils/common";

import ContainerScan, {
  ScanerImg,
  Scan,
  Line,
  ContentQr,
  Detail,
} from "./styles";

const View = () => {
  const history = useHistory();

  const [result, setResult] = useState({
    isLink: false,
    value: "",
  });

  const handleScan = (data) => {
    if (data !== null) {
      const textQr = data?.data?.split("-");
      if (textQr[0] === "vzxwallet" && textQr[1] && textQr[2]) {
        // eslint-disable-next-line max-len
        return history.push(
          `/my/wallets/${textQr[1].toLowerCase()}/withdraw/spot?address=${
            textQr[2]
          }${
            Number(textQr[3]) > 0
              ? `&amountStock=${textQr[3]}`
              : `&amountStock=0`
          }`
        );
      }
      if (textQr[0] === "payment") {
        return history.push(`/my/payment-social?orderId=${textQr[1]}`);
      }
      isValidURL(data.data);
    }
  };

  const handleClickContent = () => {
    if (result.isLink) {
      window.open(result.value);
    } else {
      copyText(result.value);
      toast.success("Copied!");
    }
  };

  const isValidURL = (string) => {
    if (string !== result.value) {
      const res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res) {
        setResult({
          isLink: true,
          value: res[0],
        });
      } else {
        setResult({
          isLink: false,
          value: string,
        });
      }
    }
  };

  const handleScannerLoad = (mode) => {
    console.log(mode);
  };

  return (
    <>
      <ContainerScan>
        {result.value && (
          <ContentQr>
            <Detail isLink={result?.isLink}>
              <span>{result.value}</span>
              <Button transparent={true} onClick={handleClickContent}>
                {result?.isLink ? "Open" : "Copy"}
              </Button>
            </Detail>
          </ContentQr>
        )}
        <>
          <Scanner
            className="some-classname"
            onDecode={handleScan}
            onScannerLoad={handleScannerLoad}
            constraints={{
              audio: false,
              video: {
                facingMode: { exact: "environment" },
              },
            }}
            captureSize={{ width: 1280, height: 720 }}
          />

          <ScanerImg>
            <Scan>
              <img src="/assets/images/icons/qrScan.svg" alt="" />
              <Line></Line>
            </Scan>
          </ScanerImg>
        </>
      </ContainerScan>
    </>
  );
};

export default View;
