"use client";

import VButton from "components/v-core/v-button";
import Histories from "./components/histories";
import MyHistory from "./components/myHistory";
import OrderToken from "./components/orderToken";
import TabToken from "./components/tabToken";
import Tokens from "./components/tokens";
import { useContext, useEffect, useState } from "react";
import { MasterContext } from "context/MasterContext";
import { useHistory } from "react-router-dom";

const StockLayout = () => {
  const history = useHistory();

  const [exchange, setExchangeList] = useState([]);
  const { balances, stocks, lockedBalances, stockDefault } =
    useContext(MasterContext);
  const [isShowAll, setIsShowAll] = useState(true);

  const [selectedTab, setSelectedTab] = useState("usdv");
  const handleTabChange = (tabValue: string) => {
    setSelectedTab(tabValue);
  };

  useEffect(() => {
    if (stocks && stocks.length > 0 && balances) {
      const _exchange = stocks.map((token: any) => ({
        ...token,
        balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
        locked_balances: lockedBalances[token.symbol]
          ? Number(lockedBalances[token.symbol])
          : 0,
      }));

      const _exchangeVnex = _exchange.find(
        (w: any) => w.symbol === stockDefault
      );
      const _exchangeNormal = _exchange.filter(
        (w: any) =>
          w.symbol !== stockDefault && (isShowAll ? true : +w.balance > 0)
      );

      setExchangeList([_exchangeVnex, ..._exchangeNormal].filter(Boolean));
    }
  }, [stocks, balances, lockedBalances, isShowAll, stockDefault]);

  const handleClickFunctionButton = (type?: string) => {
    const stock = stocks.find((a: any) => a.symbol === type);
    if (stock) {
      history.push(`/my/exchange/${stock._id}/${type || ""}`);
    }
  };

  return (
    <div className="dark:bg-vdb-light-white bg-[#161A1E] dark:text-vdb-dark-gray text-vdb-white p-2 h-screen">
      <div className="w-full h-[calc(100vh-80px)] max-lg:h-[calc(100vh-135px)] flex flex-col overflow-y-auto">
        {/*ORDER (PENDING) */}
        <div className="h-full xl:max-h-[80vh] xl:min-h-[640px] flex flex-col gap-y-2 pb-2">
          <div className="w-full h-1/2 flex gap-x-2">
            <div className="w-[25%] min-h-full max-lg:w-full">
              <TabToken />
            </div>
            <div className="w-1/2 min-h-full max-lg:hidden bg-[#1F2937] max-w-full"></div>
            <div className="w-[25%] min-h-full max-lg:hidden ">
              <Tokens
                tabs={exchange}
                tabActive={selectedTab}
                onChangeTabs={handleTabChange}
                handleClickFunctionButton={handleClickFunctionButton}
              />
            </div>
          </div>
          <div className="w-full h-1/2 flex gap-x-2">
            <div className="w-[25%] min-h-full max-lg:w-full">
              <TabToken />
            </div>
            <div className="w-1/2 min-h-full max-lg:hidden ">
              <OrderToken
                tabActive={selectedTab}
                setSelectedTab={setSelectedTab}
              />
            </div>
            <div className="w-[25%] min-h-full max-lg:hidden">
              <Histories />
            </div>
          </div>
        </div>
        <div className="max-lg:hidden row-start-2 md:row-start-3 lg:row-start-3 col-start-1 row-span-1 col-span-2 lg:col-span-4 mt-5 mb-[50px]">
          <MyHistory />
        </div>
        <div className=" flex bottom-0 w-full lg:hidden left-0">
          <div className=" flex bottom-0 w-full lg:hidden gap-x-2">
            <div className="w-1/2">
              <VButton className="w-full h-[38px] rounded-sm bg-red-500 text-black-1">
                BUY
              </VButton>
            </div>
            <div className="w-1/2">
              <VButton className="w-full h-[38px] rounded-sm bg-blue-500 text-black-1">
                SELL
              </VButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockLayout;
