"use client";
import React, { Dispatch, SetStateAction, useState } from "react";
import Order from "./order";

type IProps = {
  type?: string;
  tabActive?: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
};
const OrderToken = ({ type, tabActive, setSelectedTab }: IProps) => {
  const [btnValue, setBtnValue] = useState(type || "buy");

  const handleBuyBtn = () => {
    setBtnValue("buy");
  };

  const handleSellBtn = () => {
    setBtnValue("sell");
  };

  return (
    <div className="h-full dark:bg-white  bg-[#1F2937]">
      {/* MOBILE */}
      <div className="lg:hidden flex m-4">
        <button
          className={`w-full h-[38px] rounded-sm ${
            btnValue === "buy"
              ? "text-white bg-black-1"
              : "bg-blue-2 text-white"
          }`}
          onClick={handleBuyBtn}
        >
          Buy
        </button>
        <button
          className={`w-full h-[38px] rounded-sm ${
            btnValue === "sell"
              ? "text-white bg-black-1"
              : "bg-blue-2 text-white"
          }`}
          onClick={handleSellBtn}
        >
          Sell
        </button>
      </div>

      <div className="flex w-full h-full ">
        <div
          className={`flex-1 lg:block ${
            btnValue === "buy" ? "block" : "hidden"
          }`}
        >
          <Order
            selectedTab={tabActive}
            setSelectedTab={setSelectedTab}
            type="buy"
          />
        </div>
        <div
          className={`flex-1 lg:block h-full ${
            btnValue === "sell" ? "block" : "hidden"
          }`}
        >
          <Order
            selectedTab={tabActive}
            setSelectedTab={setSelectedTab}
            type="sell"
          />
        </div>
      </div>
    </div>
  );
};

export default OrderToken;
