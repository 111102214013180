const IconCommons = ({
  iconName,
  themesSetting,
  isHover,
  isActive,
  iconClassName,
  style,
}) => {
  return (
    <>
      {iconName === "dark" && (
        <svg
          className={iconClassName}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill={
              isHover
                ? themesSetting.icons?.hover?.color || "#FFF"
                : themesSetting.icons?.color || "#FFF"
            }
            d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z"
          ></path>
        </svg>
      )}

      {iconName === "dropdown" && (
        <svg
          className={iconClassName}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33331 5.66669L7.99998 10.3334L12.6666 5.66669"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}

      {iconName === "light" && (
        <svg
          className={iconClassName}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fill="currentColor"
            d="M12 11.807A9.002 9.002 0 0 1 10.049 2a9.942 9.942 0 0 0-5.12 2.735c-3.905 3.905-3.905 10.237 0 14.142 3.906 3.906 10.237 3.905 14.143 0a9.946 9.946 0 0 0 2.735-5.119A9.003 9.003 0 0 1 12 11.807z"
          ></path>
        </svg>
      )}
      {iconName === "copy" && (
        <svg
          className={iconClassName}
          width="12"
          height="12"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.33333 2H11.3333V4.66667H14V14H4.66667V11.3333H2V2H3.33333ZM10 4.66667V3.33333H3.33333V10H4.66667V4.66667H10ZM6 11.3333V12.6667H12.6667V6H6V11.3333Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "bell" && (
        <svg
          className={iconClassName}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "menu_mobile" && (
        <svg
          className={iconClassName}
          height="36"
          viewBox="0 0 24 24"
          width="36"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "close" && (
        <svg
          className={iconClassName}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "arrow_down_icon" && (
        <svg
          className={iconClassName}
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.1312 0.6875H0.86873C0.560917 0.6875 0.389042 1.0125 0.579667 1.23437L5.71092 7.18437C5.8578 7.35469 6.14061 7.35469 6.28905 7.18437L11.4203 1.23437C11.6109 1.0125 11.4391 0.6875 11.1312 0.6875Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "loading" && (
        <svg
          aria-hidden="true"
          className={`w-3.5 h-3.5 text-gray-200 animate-spin dark:text-gray-600 fill-white ${iconClassName}`}
          viewBox="0 0 100 101"
          width={14}
          height={14}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
      )}
      {iconName === "chevronleft" && (
        <svg
          className={iconClassName}
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {iconName === "chevronright" && (
        <svg
          className={iconClassName}
          width={20}
          height={20}
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
            clipRule="evenodd"
          />
        </svg>
      )}
      {iconName === "left_arrow_alt" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ ...style }}
        >
          <path
            d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "right_arrow_alt" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ ...style }}
        >
          <path
            d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "back" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{ ...style }}
        >
          <path d="M15 6L9 12L15 18" stroke="currentColor" stroke-width="2" />
        </svg>
      )}
      {iconName === "edit" && (
        <svg
          className={iconClassName}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.38514 17.3106L3.00751 20.4366C2.98983 20.5697 3.00087 20.705 3.0399 20.8335C3.07893 20.962 3.14503 21.0806 3.23376 21.1814C3.32249 21.2821 3.43178 21.3627 3.55427 21.4177C3.67677 21.4727 3.80962 21.5008 3.94389 21.5001C3.98388 21.5002 4.02384 21.4977 4.06351 21.4926L7.18951 21.115C7.84669 21.0317 8.45737 20.7318 8.92501 20.2626L17.025 12.1626C17.0953 12.0923 17.1348 11.9969 17.1348 11.8975C17.1348 11.798 17.0953 11.7027 17.025 11.6323L12.8693 7.47283C12.7989 7.40253 12.7036 7.36304 12.6041 7.36304C12.5047 7.36304 12.4093 7.40253 12.339 7.47283L4.23751 15.5751C3.76833 16.0427 3.4684 16.6534 3.38514 17.3106Z"
            fill="currentColor"
          />
          <path
            d="M20.4304 6.00842L18.4916 4.06967C18.3111 3.88907 18.0968 3.7458 17.8609 3.64806C17.625 3.55031 17.3722 3.5 17.1169 3.5C16.8615 3.5 16.6087 3.55031 16.3728 3.64806C16.1369 3.7458 15.9226 3.88907 15.7421 4.06967L13.9219 5.88992C13.8516 5.96025 13.8121 6.05561 13.8121 6.15505C13.8121 6.25449 13.8516 6.34985 13.9219 6.42017L18.0799 10.5782C18.1502 10.6485 18.2456 10.688 18.345 10.688C18.4444 10.688 18.5398 10.6485 18.6101 10.5782L20.4304 8.75792C20.611 8.57743 20.7542 8.36311 20.852 8.12723C20.9497 7.89134 21 7.63851 21 7.38317C21 7.12784 20.9497 6.87501 20.852 6.63912C20.7542 6.40323 20.611 6.18892 20.4304 6.00842Z"
            fill="currentColor"
          />
          <path
            d="M21 20.75C21 20.5511 20.921 20.3603 20.7803 20.2197C20.6397 20.079 20.4489 20 20.25 20H11.625C11.4261 20 11.2353 20.079 11.0947 20.2197C10.954 20.3603 10.875 20.5511 10.875 20.75C10.875 20.9489 10.954 21.1397 11.0947 21.2803C11.2353 21.421 11.4261 21.5 11.625 21.5H20.25C20.4489 21.5 20.6397 21.421 20.7803 21.2803C20.921 21.1397 21 20.9489 21 20.75Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "save" && (
        <svg
          className={iconClassName}
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.0233 5.51495L19.485 3.97672C18.86 3.3508 18.0126 3 17.1283 3H4.29172C3.02585 3 2 4.02585 2 5.29172V20.7083C2 21.9742 3.02585 23 4.29172 23H19.7083C20.9742 23 22 21.9742 22 20.7083V7.87167C22 6.98743 21.6492 6.13995 21.0233 5.51495ZM5.33328 8.20828V6.125C5.33328 5.78 5.61328 5.5 5.95828 5.5H14.7083C15.0533 5.5 15.3333 5.78 15.3333 6.125V8.20828C15.3333 8.55328 15.0533 8.83328 14.7083 8.83328H5.95828C5.61328 8.83328 5.33328 8.55328 5.33328 8.20828ZM12 19.6667C9.92923 19.6667 8.25 17.9875 8.25 15.9167C8.25 13.8458 9.92923 12.1667 12 12.1667C14.0708 12.1667 15.75 13.8458 15.75 15.9167C15.75 17.9875 14.0708 19.6667 12 19.6667Z"
            fill="currentColor"
          />
        </svg>
      )}

      {iconName === "send" && (
        <svg
          className={iconClassName}
          width="16"
          height="16"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.5526 1.75994L2.50258 7.66244C2.14969 7.75611 1.83649 7.96122 1.60957 8.24724C1.38265 8.53326 1.25415 8.88491 1.2432 9.24984C1.23224 9.61478 1.33941 9.97351 1.54876 10.2726C1.75811 10.5717 2.05845 10.7953 2.40508 10.9099L11.0451 13.8049C11.0766 13.8169 11.1054 13.835 11.1298 13.8582C11.1543 13.8814 11.1739 13.9091 11.1876 13.9399L14.3676 22.1899C14.4963 22.5214 14.7234 22.8054 15.0184 23.0039C15.3134 23.2023 15.6621 23.3057 16.0176 23.2999H16.0626C16.424 23.2988 16.7759 23.1837 17.0682 22.9709C17.3604 22.7581 17.578 22.4586 17.6901 22.1149L23.7501 3.91994C23.8451 3.62863 23.859 3.31694 23.7903 3.01833C23.7216 2.71971 23.5728 2.44544 23.3601 2.22494C23.1325 1.98602 22.8425 1.81576 22.5229 1.73356C22.2034 1.65135 21.8672 1.66049 21.5526 1.75994Z"
            fill="currentColor"
          />
        </svg>
      )}

      {iconName === "auction" && (
        <svg
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5001 22.1002H13.1001C13.3201 22.1002 13.5001 21.9202 13.5001 21.7002V21.5362C13.5001 20.5242 12.6761 19.7002 11.6681 19.7002H4.9321C3.9241 19.7002 3.1001 20.5242 3.1001 21.5362V21.7002C3.1001 21.9202 3.2801 22.1002 3.5001 22.1002Z"
            fill="currentColor"
          />
          <path
            d="M6.2322 16.5C5.3882 16.5 4.7002 17.188 4.7002 18.036V18.9H11.9002V18.036C11.9002 17.188 11.2122 16.5 10.3682 16.5H6.2322Z"
            fill="currentColor"
          />
          <path
            d="M5.20819 13.1001L8.32819 14.4641C8.50819 14.5401 8.70019 14.5801 8.89619 14.5801C9.06819 14.5801 9.24419 14.5481 9.41219 14.4841C9.76819 14.3441 10.0442 14.0761 10.1962 13.7281C10.3482 13.3801 10.3562 12.9921 10.2162 12.6401C10.1242 12.4001 9.96819 12.1921 9.76819 12.0401L10.3802 10.6761L20.3202 15.2401C20.4162 15.2801 20.5162 15.3001 20.6162 15.3001C20.7082 15.3001 20.8002 15.2841 20.8842 15.2481C21.0722 15.1761 21.2162 15.0361 21.2922 14.8561L21.8402 13.6081C21.9162 13.4281 21.9202 13.2281 21.8482 13.0441C21.7762 12.8601 21.6362 12.7121 21.4562 12.6361C21.4522 12.6321 21.4482 12.6321 21.4442 12.6281L11.3162 8.60814L12.0002 7.09214C12.0602 7.10014 12.1162 7.10414 12.1762 7.10414C12.3522 7.10414 12.5282 7.07214 12.6962 7.00414C13.0482 6.86814 13.3282 6.60014 13.4802 6.25214C13.6322 5.90414 13.6402 5.51614 13.5002 5.16414C13.3602 4.80814 13.0922 4.53214 12.7442 4.38014L9.62419 3.02014C9.28019 2.86814 8.89219 2.86014 8.54019 3.00014C8.18419 3.14014 7.90819 3.40814 7.75619 3.75614C7.60419 4.10014 7.59619 4.48814 7.73619 4.84014C7.82419 5.06814 7.96819 5.26014 8.14819 5.41214L5.90019 10.3841C5.68419 10.3641 5.46419 10.3961 5.25619 10.4761C4.90419 10.6161 4.62419 10.8841 4.47219 11.2321C4.32019 11.5801 4.31619 11.9641 4.45219 12.3201C4.59219 12.6721 4.86019 12.9521 5.20819 13.1001Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "tag" && (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.6666 2H13.7394C13.2812 2 12.6407 2.26548 12.3169 2.58901L2.81974 12.0862C2.17125 12.7339 2.17125 13.7959 2.81974 14.4429L9.89117 21.5141C10.5382 22.1618 11.5992 22.1618 12.2472 21.5133L21.7444 12.0177C22.0679 11.6942 22.3334 11.0527 22.3334 10.5954V3.66674C22.3334 2.75024 21.5831 2 20.6666 2ZM17.3331 8.6667C16.4126 8.6667 15.6664 7.91969 15.6664 6.99997C15.6664 6.07873 16.4126 5.33323 17.3331 5.33323C18.2536 5.33323 19.0001 6.07873 19.0001 6.99997C19.0001 7.91969 18.2536 8.6667 17.3331 8.6667Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "hour_glass" && (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.4117 17.865C18.6188 18.165 18.74 18.516 18.762 18.8799C18.7841 19.2439 18.7062 19.6069 18.5368 19.9297C18.3674 20.2526 18.1129 20.523 17.8009 20.7116C17.4889 20.9002 17.1312 21 16.7667 21H8.56665C8.20205 21 7.84441 20.9002 7.53241 20.7116C7.22041 20.523 6.96594 20.2526 6.79653 19.9297C6.62713 19.6069 6.54923 19.2439 6.57129 18.8799C6.59334 18.516 6.7145 18.165 6.92165 17.865L10.9516 12L6.92165 6.135C6.7145 5.83497 6.59334 5.484 6.57129 5.12007C6.54923 4.75614 6.62713 4.39311 6.79653 4.07026C6.96594 3.74742 7.22041 3.47704 7.53241 3.2884C7.84441 3.09975 8.20205 3.00002 8.56665 3H16.7667C17.1312 3.00002 17.4889 3.09975 17.8009 3.2884C18.1129 3.47704 18.3674 3.74742 18.5368 4.07026C18.7062 4.39311 18.7841 4.75614 18.762 5.12007C18.74 5.484 18.6188 5.83497 18.4117 6.135L14.3816 12L18.4117 17.865Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "filter" && (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5 4C4.44772 4 4 4.44772 4 5V7.00001H20V5C20 4.44772 19.5523 4 19 4H5ZM19.7822 9.00001H4.21776C4.3321 9.22455 4.48907 9.42794 4.68299 9.59762L10.683 14.8476C11.437 15.5074 12.563 15.5074 13.317 14.8476L19.317 9.59762C19.5109 9.42794 19.6679 9.22455 19.7822 9.00001Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14 18.7049L14 12H10V20.7049L14 18.7049Z"
            fill="currentColor"
          />
        </svg>
      )}
      {iconName === "signOut" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M16 13v-2H7V8l-5 4 5 4v-3z" fill="currentColor"></path>
          <path
            d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "badge-check" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
        >
          <path
            d="M4.035 15.479A3.976 3.976 0 0 0 4 16c0 2.378 2.138 4.284 4.521 3.964C9.214 21.198 10.534 22 12 22s2.786-.802 3.479-2.036C17.857 20.284 20 18.378 20 16c0-.173-.012-.347-.035-.521C21.198 14.786 22 13.465 22 12s-.802-2.786-2.035-3.479C19.988 8.347 20 8.173 20 8c0-2.378-2.143-4.288-4.521-3.964C14.786 2.802 13.466 2 12 2s-2.786.802-3.479 2.036C6.138 3.712 4 5.622 4 8c0 .173.012.347.035.521C2.802 9.214 2 10.535 2 12s.802 2.786 2.035 3.479zm1.442-5.403 1.102-.293-.434-1.053A1.932 1.932 0 0 1 6 8c0-1.103.897-2 2-2 .247 0 .499.05.73.145l1.054.434.293-1.102a1.99 1.99 0 0 1 3.846 0l.293 1.102 1.054-.434C15.501 6.05 15.753 6 16 6c1.103 0 2 .897 2 2 0 .247-.05.5-.145.73l-.434 1.053 1.102.293a1.993 1.993 0 0 1 0 3.848l-1.102.293.434 1.053c.095.23.145.483.145.73 0 1.103-.897 2-2 2-.247 0-.499-.05-.73-.145l-1.054-.434-.293 1.102a1.99 1.99 0 0 1-3.846 0l-.293-1.102-1.054.434A1.935 1.935 0 0 1 8 18c-1.103 0-2-.897-2-2 0-.247.05-.5.145-.73l.434-1.053-1.102-.293a1.993 1.993 0 0 1 0-3.848z"
            fill="currentColor"
          ></path>
          <path
            d="m15.742 10.71-1.408-1.42-3.331 3.299-1.296-1.296-1.414 1.414 2.704 2.704z"
            fill="currentColor"
          ></path>
        </svg>
      )}
      {iconName === "check-circle" && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          className={iconClassName}
        >
          <path d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
          <path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path>
        </svg>
      )}
    </>
  );
};

export default IconCommons;
