"use client";

import React, { useState } from "react";
import VTable from "components/v-core/v-table";

const columns = [
  {
    field: "user",
    title: "User",
  },
  {
    field: "price",
    title: "Price",
  },
  {
    field: "amount",
    title: "Amount (VNEX)",
  },
  {
    field: "total",
    title: "Total (USDT)",
  },
];

const images = [
  {
    id: 1,
    img: "/btn_all.svg",
  },
  {
    id: 2,
    img: "/btn_buy.svg",
  },
  {
    id: 3,
    img: "/btn_sell.svg",
  },
];

const TabToken = ({ data = [] }) => {
  const [selectedTab, setSelectedTab] = useState("my_order_pending");

  const filteredData =
    selectedTab === "my_order_pending"
      ? data.filter((item) => item.status === "pending")
      : data.filter((item) => item.status === "success");

  const cl = columns;

  return (
    <div className=" h-full">
      <div className="flow-root h-full">
        <div className=" overflow-x-auto h-full !no-scrollbar">
          <div className="inline-block min-w-full  align-middle">
            <div className="flex px-2">
              {images.map((image, index) => (
                <img
                  key={index}
                  className="size-7 rounded-full object-cover"
                  src={image.img}
                  alt="avatar"
                />
              ))}
            </div>

            <div>
              <VTable
                className="text-white"
                isNobackground={true}
                isNoBorder={true}
                columns={cl}
                data={filteredData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabToken;
