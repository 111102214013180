/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";

import { Route, Switch } from "react-router-dom";
import Profile from "./profile";
import Swap from "./swap";
import Wallets from "./wallets";
// import Referral from "./profile/referral";
import Shop from "./shop";
import IDO from "./ibo-project";
import IBO from "./my-project";
import TASK from "./task";
import TASK_DETAIL from "./task/detail";
import withAuth from "HOCs/withAuth";
import UserLayout from "../../layouts/UserLayout";
import { ROUTES } from "config/routes";
import PetaVerse from "./petaverse";
import Invests from "./invests";
import Reward from "./rewards";
import Channel from "./channel";
import Statis from "./statis";
import QA from "./q&a";
import Campaign from "./campaign";
import CampaignDetail from "./campaign/components/Details";
import Supports from "./supports";
import Dashboard from "./dashboard";
import Rounds from "./ibo-project/Rounds";
import PreviewChannel from "./profile/channel/preview-channel";
import { SocketContext } from "context/SocketContext";
import Webview from "./webview";
import ComingSoon from "pages/comming-soon";
import PdfFiles from "pages/pdf-files";
import { Social } from "./social";
import LoanPackage from "./loan-package";
import LoanDetails from "./loan-package/components/LoanDetails";
import Rankings from "./rankings";
import Exchange from "pages/exchange/exchange_token";
import PaymentSocialPage from "./payment-social";

function MyRoute() {
  const { connectSocket, connectSocketAd } = useContext(SocketContext);

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      connectSocket();
      connectSocketAd();
    }
  }, []);

  return (
    <UserLayout>
      <Switch>
        <Route path={ROUTES.MY_PROFILE} component={Profile} />
        <Route path={ROUTES.MY_SWAP} component={Swap} />
        <Route path={ROUTES.MY_WALLETS} component={Wallets} />
        {/* <Route path={ROUTES.MY_REFERRALS} component={Referral} /> */}
        <Route path={ROUTES.SALESTATISTICS} component={Statis} />
        <Route path={ROUTES.MY_SHOP} component={Shop} />
        <Route path={ROUTES.MY_IBO_ROUND} component={Rounds} />
        <Route path={ROUTES.MY_IDO} component={IDO} />
        <Route path={ROUTES.MY_IBO} component={IBO} />
        <Route path={ROUTES.MY_TASK_DETAIL} component={TASK_DETAIL} />
        <Route path={ROUTES.MY_TASK} component={TASK} />
        <Route path={ROUTES.PETAVERSE} component={PetaVerse} />
        <Route path={ROUTES.INVEST} component={Invests} />
        <Route path={ROUTES.REWARD} component={Reward} />
        <Route path={ROUTES.CHANNEL} component={Channel} />
        <Route path={ROUTES.QA} component={QA} />
        <Route path={ROUTES.CAMPAIGNDETAIL} component={CampaignDetail} />
        <Route path={ROUTES.CAMPAIGN} component={Campaign} />
        <Route path={ROUTES.SUPPORTS} component={Supports} />
        <Route path={ROUTES.MY_COMING_SOON} component={ComingSoon} />
        <Route path={ROUTES.MY_EXCHANGE} component={Exchange} />
        <Route path={ROUTES.MY_PDF} component={PdfFiles} />
        <Route path={ROUTES.MY_SOCIAL} component={Social} />
        <Route path={ROUTES.MY_LOAN_PACKAGE} component={LoanPackage} />
        <Route path={ROUTES.LOAN_DETAIL} component={LoanDetails} />
        <Route path={ROUTES.MY_RANKINGS} component={Rankings} />
        <Route path={ROUTES.DASHBOARD} component={Dashboard} />
        <Route path={ROUTES.PREVIEW_CHANNEL} component={PreviewChannel} />
        <Route path={ROUTES.MY_WEBVIEW} component={Webview} />
        <Route path={ROUTES.MY_PAYMENT_SOCIAL} component={PaymentSocialPage} />
      </Switch>
    </UserLayout>
  );
}

export default withAuth(MyRoute);
