import { useContext } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";

import NavBarBottom from "../MainLayout/navbar-bottom";
import { ThemeContext } from "context/ThemeContext";
import BackgroundVideoComponent from "pages/create-landing-page/iframeVideo";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
// import { getLinkYoutubeIframe } from "utils/common";
// import { convertIdVideo } from "utils/convertIdVideo";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { theme, handleSwitchTheme, logos, themesSetting } =
    useContext(ThemeContext);
  const history = useHistory();

  return (
    <div
      style={{ color: themesSetting.layout.textLoginPage }}
      className="relative bg-black h-screen"
    >
      <BackgroundVideoComponent link={logos?.backgroundLogin} />
      <div className="z-1 relative h-full">
        <div className="flex items-start justify-center md:justify-between p-[14px] md:p-6 cursor-pointer">
          <img
            onClick={() => history.push("/")}
            src={logos?.logo}
            className={` ${
              props?.page === "event" && "w-[150px]"
            } md:max-w-[250px] max-w-[200px] h-[50px]`}
          />
          {/* <div
            className="flex items-center justify-center"
            onClick={handleSwitchTheme}
          > */}
          <VButton
            radius="full"
            onClick={handleSwitchTheme}
            className="border-[1px] bg-transparent w-9 h-9 mr-3 !border-[#ff9900]"
          >
            {theme === "light" ? (
              <Icons iconName="light" />
            ) : (
              <Icons iconName="dark" />
            )}
          </VButton>
        </div>
        {/* </div> */}

        <div className="flex justify-center h-[calc(100vh-150px)] lg:h-[calc(100vh-115px)] items-center">
          {props.children}
        </div>
        {isMobile && <NavBarBottom />}
      </div>
    </div>
  );
}
