import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/menus/all`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByType = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/menus/by-type?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const MenuService = {
  getAll,
  getByType,
};

export default MenuService;
