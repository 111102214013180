/* eslint-disable no-lone-blocks */
/* eslint-disable indent */
import { ThemeContext } from "context/ThemeContext";
import Icons from "helper/icons";
import { ReactNode, useContext, useMemo, useState } from "react";

interface IProp {
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  className?: string;
  direction?: "vertical" | "horizontal";
  children?: React.ReactNode;
  loading?: boolean;
  iconName?: string;
  disabled?: boolean;
  type?: "primary" | "secondary" | "outline";
  transparent?: boolean;
  radius?: string;
  px?: string;
  py?: string;
  style?: any;
  iconLeftClassName?: string;
  buttonCurrent?: string;
  onClick?: () => any;
}

const VButton = ({
  iconLeft,
  iconRight,
  iconName,
  className,
  direction = "horizontal",
  children,
  loading,
  disabled,
  type = "primary",
  transparent = false,
  radius,
  px,
  py,
  style,
  iconLeftClassName = "",
  buttonCurrent,
  ...props
}: IProp) => {
  const { themesSetting, theme } = useContext(ThemeContext);

  const [buttonActive, setButtonActive] = useState(null);

  const propsClassName = useMemo(() => {
    const classNames = [];
    {
      px ? classNames.push(`px-${px}`) : classNames.push("px-2");
    }
    {
      py ? classNames.push(`py-${py}`) : classNames.push("py-2");
    }
    {
      radius
        ? classNames.push(`rounded-${radius}`)
        : classNames.push("rounded");
    }
    {
      transparent ? classNames.push("!bg-transparent") : classNames.push("");
    }
    {
      direction === "vertical"
        ? classNames.push("flex-col gap-y-2")
        : classNames.push("flex-row gap-x-2");
    }

    return classNames.join(" ");
  }, [px, py, radius, transparent, direction]);

  const hoverColor = themesSetting.button[type].hoverColor;

  const hoverClass = useMemo(() => {
    if (hoverColor === "v-yellow") return "hover:!bg-v-yellow";
    if (hoverColor === "v-blue300") return "hover:!bg-v-blue300";
    if (hoverColor === "v-blue") return "hover:!bg-v-blue";
    if (hoverColor === "v-orange") return "hover:!bg-v-orange";
    if (hoverColor === "v-purple") return "hover:!bg-v-purple";
    if (hoverColor === "v-red") return "hover:!bg-v-red";
    if (hoverColor === "v-orange") return "hover:!bg-v-orange";

    return "";
  }, [hoverColor]);

  return (
    <button
      onFocus={(e) => setButtonActive(e.target.innerText)}
      disabled={loading || disabled}
      className={`relative group flex items-center justify-center  hover:-translate-y-[1px] transition-all ease-in-out duration-150 whitespace-nowrap overflow-hidden
      ${(loading || disabled) && "opacity-45"}
      ${propsClassName} ${className}`}
      style={
        String(buttonActive).toLowerCase() ===
          String(buttonCurrent).toLowerCase() && type === "outline"
          ? themesSetting.button[type].active
          : themesSetting.button[type]
      }
      {...props}
    >
      <div
        className="hidden group-hover:block glow-on-hover"
        style={{ backgroundImage: themesSetting?.button[type]?.hoverColor }}
      ></div>
      {!!loading && (
        <div className="flex items-center justify-center">
          <Icons iconName="loading" />
        </div>
      )}
      {!loading && iconLeft && (
        <div
          className={`flex items-center justify-center ${iconLeftClassName}`}
        >
          {iconLeft}
        </div>
      )}
      {(children || iconRight) && (
        <>
          {children && children}
          {iconRight && (
            <div className="flex items-center justify-center">{iconRight}</div>
          )}
        </>
      )}
    </button>
  );
};
export default VButton;
