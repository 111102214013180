import TransactionService from "api/transactions";
import axios from "axios";
import VButton from "components/v-core/v-button";
import Icons from "helper/icons";
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatNumber } from "utils/common";
import { renderStock } from "utils/render";
import { useHistory } from "react-router-dom";

const PaymentSocialPage = () => {
  const query = useQuery();
  const history = useHistory();
  const { orderId = "" } = query;

  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState<any>(null);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (orderId) {
      getOrder(orderId);
    }
  }, [orderId]);

  const getOrder = async (orderId: string) => {
    try {
      const res: any = await axios.get(
        `${process.env.REACT_APP_SOCIAL_API_URL}/purchases/orders/${orderId}`
      );
      const { data } = res.data;
      setOrderData(data);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await TransactionService.paymentOrderSocial({
        body: {
          orderId: orderData?._id,
          amount: orderData?.cal?.total_stock,
          stock: orderData?.data?.stock,
          productName: orderData?.product?.name,
        },
      });
      if (res) {
        toast.success("Success");
        setIsSuccess(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
    }
  };

  const handleComplete = () => {
    history.push("/");
  };
  return (
    <>
      {orderData ? (
        <>
          {!isSuccess ? (
            <div className="max-w-sm flex flex-col items-center gap-y-10 mx-auto mt-[40px] p-[20px] rounded-lg mb-[56px]">
              <div className="w-full flex flex-col gap-y-6">
                <div className="w-full flex flex-col items-center gap-y-2">
                  <img
                    src={`https://file.vdiarybook.com/api/files/${orderData?.product?.info?.thumbnail}`}
                    alt=""
                    className="h-[150px] max-w-full"
                  />
                  <span className="text-xl font-bold">
                    {orderData?.product?.name}
                  </span>
                </div>
                <div className="w-full flex flex-col gap-y-4">
                  <div className="flex items-center justify-between gap-x-2">
                    <span>User ID:</span>
                    <span className="font-medium">
                      {orderData?.user?.refer_code}
                    </span>
                  </div>
                  <div className="flex items-center justify-between gap-x-2">
                    <span>User information:</span>
                    <div className="flex items-center gap-x-2">
                      <img
                        className="w-6 h-6 rounded-full"
                        src={`https://file.vdiarybook.com/api/files/${orderData?.user?.avatar}`}
                        alt="avatar"
                      />
                      <span className="font-medium">
                        {orderData?.user?.full_name}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between gap-x-2">
                    <span>Price:</span>
                    <span className="font-medium">
                      {formatNumber(orderData?.cal?.price_stock)} (
                      {renderStock(orderData?.data?.stock)})
                    </span>
                  </div>
                  <div className="flex items-center justify-between gap-x-2">
                    <span>Discount:</span>
                    <span className="font-medium">
                      {orderData?.cal?.discount_stock}
                    </span>
                  </div>
                  <div className="flex items-center justify-between gap-x-2">
                    <span>Total:</span>
                    <span className="font-bold">
                      {formatNumber(orderData?.cal?.total_stock)} (
                      {renderStock(orderData?.data?.stock)})
                    </span>
                  </div>
                </div>
              </div>
              {orderData?.status === "success" && (
                <div className="flex items-center gap-x-2">
                  <Icons iconName="check" />
                  <span className="text-sm font-semibold text-green-500">
                    This order has been successfully paid
                  </span>
                </div>
              )}
              <VButton
                loading={loading}
                radius="full"
                className={"!w-full max-w-sm"}
                onClick={handleSubmit}
                disabled={orderData?.status === "success"}
              >
                Submit
              </VButton>
            </div>
          ) : (
            <div className="w-full flex flex-col items-center gap-y-4 mt-[40px]">
              <div className="w-full max-w-sm flex flex-col items-center gap-y-2 rounded-lg py-10">
                <Icons
                  iconName="check-circle"
                  className="!text-green-500"
                  iconClassName="w-24 h-24"
                />
                <span className="font-bold text-green-500 text-2xl">
                  Successfully !
                </span>
                <span className="font-bold text-green-500 text-2xl">
                  {" "}
                  {formatNumber(orderData?.cal?.total_stock)} (
                  {renderStock(orderData?.data?.stock)})
                </span>
              </div>
              <div className="w-full max-w-xs">
                <VButton
                  className="w-full"
                  onClick={handleComplete}
                  radius="full"
                >
                  Back home
                </VButton>
              </div>
            </div>
          )}
        </>
      ) : (
        <span className="text-2xl font-bold text-center">
          Purchase not found !
        </span>
      )}
    </>
  );
};

export default PaymentSocialPage;
