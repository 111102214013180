/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Packages from "./packages";
import History from "./history";
import ExpectValue from "./expect-value";
import VTabs from "components/v-core/v-tabs";
import { useHistory } from "react-router-dom";
import { MasterContext } from "context/MasterContext";
import ComingSoon from "components/coming-soon";

interface IProps {
  [key: string]: any;
}

export default function Trust(props: IProps) {
  const { getTabPositon } = useContext(MasterContext);
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const currentTab = searchParams.get("tab");

  useEffect(() => {
    if (!currentTab) history.push("?tab=packages");
  });
  const handleTabs = (tab: string) => {
    history.push(`?tab=${tab}`);
  };

  return (
    <div className="p-2">
      <VTabs
        tabs={getTabPositon("tab_page_trust").map(
          (item: any, index: number) => ({
            name: item.name,
            value: item.key,
          })
        )}
        tabActive={currentTab}
        onChangeTabs={handleTabs}
      />
      <div className="mt-3">
        {currentTab === "packages" && <Packages />}
        {currentTab === "histories" && <History />}
        {currentTab === "expect_value" && <ExpectValue />}
        {currentTab === "dividends" && <ComingSoon />}
        {currentTab === "stock_certificates" && <ComingSoon />}
      </div>
    </div>
  );
}
