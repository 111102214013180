import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getTransactions = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/transactions/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTransactionsDonate = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/transactions/donate?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTransactionById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/transactions/get-by-id/${req.params.id}/`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTransactionBuyType = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `/transactions/type/?stock=${req.query.stock}&&type=${req.query.type}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const donate = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/donate`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const transfer = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/transfer`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdraw = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/withdraw`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdrawCV = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/withdraw-cv`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdrawQRMart = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/withdraw-qrmart`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPayments = (req) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`/payments/${req.params.userId}/?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const claimByLeader = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/claim-by-leader`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocks = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/transactions/locks/${req.params.stock}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdrawVdiarybook = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/withdraw-vdiarybook`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

  const paymentOrderSocial = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/transactions/payment-order-social`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const TransactionService = {
  transfer,
  withdraw,
  getTransactions,
  getPayments,
  getTransactionById,
  getTransactionBuyType,
  donate,
  getTransactionsDonate,
  claimByLeader,
  withdrawCV,
  withdrawQRMart,
  getLocks,
  withdrawVdiarybook,
  paymentOrderSocial,
};

export default TransactionService;
