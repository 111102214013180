import React, { useState } from "react";
import VTable from "components/v-core/v-table";
import VTabs from "components/v-core/v-tabs";

const Histories = ({ data = [] }) => {
  const [selectedTab, setSelectedTab] = useState("pair_history");
  const tabs = [
    {
      name: "Pair histories",
      value: "pair_history",
    },
    {
      name: "Market histories",
      value: "market_history",
    },
  ];

  const columnsPair = [
    {
      field: "user",
      title: "User",
    },
    {
      field: "price",
      title: "Price",
    },
    {
      field: "amount",
      title: "From (CVXM)",
    },
    {
      field: "total",
      title: "To (USDT)",
    },
  ];

  const columnsMarket = [
    {
      field: "User",
      title: `User`,
    },
    {
      field: "price",
      title: "Price",
    },
    {
      field: "amount",
      title: `From`,
    },
    {
      field: "total",
      title: `To`,
    },
    {
      field: "status",
      title: `Status`,
    },
  ];

  const filteredData =
    selectedTab === "pair_history"
      ? data.filter((item) => item.type === "pair")
      : data.filter((item) => item.type === "market");

  const columns = selectedTab === "pair_history" ? columnsPair : columnsMarket;

  return (
    <div className="no-scrollbar border-gray-600 dark:border-gray-200 pt-1 ">
      <ul className="flex -mb-px text-sm font-medium text-center text-white dark:text-gray-400">
        <VTabs
          tabs={tabs}
          tabActive={selectedTab}
          onChangeTabs={(i) => setSelectedTab(i)}
        />
      </ul>
      <div className="text-white">
        <VTable
          isNobackground={true}
          isNoBorder={true}
          columns={columns}
          data={filteredData}
        />
      </div>
    </div>
  );
};

export default Histories;
